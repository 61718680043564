import styled from 'styled-components';

interface ItemDivProps {
    sold: boolean;
}

const backgroundGradient = `
    background-color: #ccc;
    background-image: repeating-linear-gradient(45deg, transparent, transparent 1px, rgba(255,255,255,.5) 1px, rgba(255,255,255,.5) 9px);
`;

const backgroundWhite = `
    background-color: #FFF;
`;

export const AuctionItemDiv = styled.div<ItemDivProps>`
    ${props => props.sold ? backgroundGradient : backgroundWhite}
    color: #000;
    font-size: 24px;
    list-style-type: none;
    padding: 1rem;
    text-align: center;
    text-transform: capitalize;
    font-weight: 600;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
`;

export const AuctionItemTitle = styled.h1`
    font-size: 30px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: -1px;
    text-align: left;
    color: #0019D6;
`;

export const AuctionItemDescription = styled.p`
    font-size: 16px;
    text-align: left;
    width: 100%;
`;

export const AuctionItemImage = styled.img`
    max-width: 100%;
    max-height: 250px;
`;

export const AuctionItemImageLink = styled.a`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
`;

export const AuctionItemSellerName = styled.p`
    font-size: 14px;
    text-align: right;
    align-self: flex-end;
    width: 100%;
`;

export const AuctionItemWinnerInfo = styled.p`
    font-size: 14px;
    text-align: right;
    align-self: flex-end;
    width: 100%;
    color: red;
`;

// Spinner pinched from https://gist.github.com/knowbody/578b35164b69e867ed4913423f6bed30
export const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
  
  & .path {
    stroke: #5652BF;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export const StyledSpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
`;