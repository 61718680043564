import axios from 'axios';
import mockResponse from './mockResponse.json';
export default class SheetService {
    constructor(
        private readonly sheetUrl: string = 'https://yjxvjuakc8.execute-api.ap-southeast-2.amazonaws.com/Prod/auctions'
    ) {}

    public async getData() {
        const response = await axios.get(this.sheetUrl);
        if (!response?.data?.message?.values) {
            console.error('Bad data!!! Returning mocked response data instead.');
            const mockedResponse = { ...mockResponse };
            mockedResponse.message.values.shift();
            return mockedResponse.message.values;
        }
        response.data.message.values.shift();
        return response.data.message.values;
    }
}